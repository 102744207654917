
<template>
<div @click="toggleOff()">

	<transition name="fade">	
		<div v-if="status" class="bg-overlay-white fixed w-full absolute flex-1 h-full z-30" style="height: 100%;"></div>
		<!-- <div v-if="status" class="bg-overlay-white fixed w-full absolute flex-1 h-full z-30" style="height: calc(100% + 96px);"></div> -->
	</transition>

	<div @click.stop class="sidebar w-17r sm:w-19r lg:w-30r left-0 flex flex-col absolute flex-1 h-full bg-white z-40 shadow-2xl" 
		:class="{'visible' : status}" style="height: 100%;z-index: 140;">
		<!-- :class="{'visible' : status}" style="height: calc(100% + 96px);z-index: 140;"> -->
		<div class="bg-rambam-darker-yellow-orange md:h-64 w-full flex relative">
			<div @click.stop="toggleOff" 
			class="absolute active:bg-r-darker-orange bg-r-lighter-orange cursor-pointer h-12 mr-5 mt-5 p-2 rounded-full text-white w-12 z-30" style="right:0">
				<i class="material-icons navbar-close" style="font-size: 2rem !important">chevron_right</i>
			</div>
			<div class="my-auto p-4 px-8 block lg:flex w-full">
				<div class="h-24 w-24 md:h-24 md:w-24 rounded-full overflow-hidden ml-0 mr-4 mb-2" style="display: -webkit-inline-box;">
					<img class="image bg-white" :src="url+user.image" alt="" @error="imageLoadError">
				</div>
				<div class="flex items-center font-Alef text-lg md:text-1xl lg:text-2xl text-white capitalize font-bold flex">
					<span class="text-ellipsis w-full lg:w-56">{{user.name}} {{user.family}}</span>
				</div>
			</div>
		</div>	
		<div class="h-full flex">
			<div class="p-4 flex flex-col justify-between sidebar-h-100">
				<ul class="lg:px-10 pt-10">
					<li class="h-16 flex items-center p-3 mobile:px-3 mobile:h-8 mobile:py-5 md:px-8 w-full mb-8 cursor-pointer hover:bg-r-transparent-orange active:bg-r-overlay-light rounded-full" 
					@click="route('home')">
						<i class="material-icons navbar-close mr-3" style="font-size: 2rem !important">home</i>
						<!-- <img :src="'/assets/svg/store-icon.svg'" class="image ml-5" alt=""> -->
						<span class="font-Alef font-bold text-sm sm:text-1xl lg:text-lg uppercase">
							Home</span>
					</li>					
					<li class="h-16 flex items-center p-3 mobile:px-3 mobile:h-8 mobile:py-5 md:px-8 w-full mb-8 cursor-pointer hover:bg-r-transparent-orange active:bg-r-overlay-light rounded-full" 
					@click="route('daily-rambam')">
						<i class="material-icons navbar-close mr-3" style="font-size: 2rem !important">book</i>

						<!-- <img :src="'/assets/svg/history-icon.svg'" class="image ml-5" alt=""> -->
						<span class="font-Alef font-bold text-sm sm:text-1xl lg:text-lg uppercase">
							{{translations.sidebar.todays_mitzva}}</span>
					</li>					
					<li class="h-16 flex items-center p-3 mobile:px-3 mobile:h-8 mobile:py-5 md:px-8 w-full mb-8 cursor-pointer hover:bg-r-transparent-orange active:bg-r-overlay-light rounded-full" 
					@click="route('archive')">
						<i class="material-icons navbar-close mr-3" style="font-size: 2rem !important">all_inbox</i>

						<!-- <img :src="'/assets/svg/history-icon.svg'" class="image ml-5" alt=""> -->
						<span class="font-Alef font-bold text-sm sm:text-1xl lg:text-lg uppercase">
							{{translations.sidebar.archive}}</span>
					</li>	
				</ul>				
				<ul class="pt-10">
					<div>
						<li class="sm:h-16 flex items-center p-3 mobile:px-3 mobile:h-8 mobile:py-5 md:px-8 w-full mb-8 cursor-pointer hover:bg-r-transparent-orange active:bg-r-overlay-light rounded-full" 
						@click="logout()">
							<i class="material-icons navbar-close mr-3" style="font-size: 2rem !important">exit_to_app</i>
							<span class="font-Alef font-bold text-sm sm:text-1xl lg:text-lg uppercase">
							Logout</span>
						</li>							
					</div>
				</ul>
			</div>
		</div>
	</div>	

</div>
</template>
<script>
	export default {
		
		data() {

			return {
				url: '/assets/images/avatars/',
				default_image: 'default.png',
			}
		},

		computed: {

			user() {
				return this.$store.getters.getUser;
			},

			status() {
				return this.$store.getters.getSidebarStatus
			},

			pageName(){
				return this.$route.name
			},

			translations() {
				return this.$store.getters.getTranslations
			}

		},

		methods: {

	  		imageLoadError: function () {
	  			event.target.src = this.url+this.default_image;
	  		},

			toggleOff: function () {
				return this.$store.dispatch('closeSidebar')
			},

			route: function (route) {
				if(route == this.pageName){return this.toggleOff()}
				this.toggleOff()
				this.$router.push({name:route})
			},

			logout: function () {
				this.toggleOff()
				this.$store.dispatch('userLogout')
			},
		},

		beforeCreate() {
			this.$store.dispatch( 'getTranslations' );
		},

	}
</script>