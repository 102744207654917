import LandingAPI from '../api/landing.js';

export const landing = {

    state: {
    	landing: { main_block_text: "", main_block_header: "" },
    	landing_loading: 0,
    },

    getters: {    	

        getLandingLoadingStatus(state) {
            return state.landing_loading;
        }, 

        getLandingPage(state) {
        	return state.landing;
        }, 

    },
    actions: {   

        //get landing page
        getLandingPage({commit, dispatch}, data) {
            
            commit( 'setLandingLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                LandingAPI.getLandingPage( data )
                .then( function( response ){
                	commit( 'setLanding', response.data );
                    commit( 'setLandingLoadStatus', 2 );
                    resolve(response.data)

                })
                .catch( function(error){
                    commit( 'setLandingLoadStatus', 3 );
                    reject(error.response.data)
                }); 

            })
            
        },               
          
              

    },

    mutations: {

  	    setLandingLoadStatus( state, status ){
    		state.landing_loading = status;
  	    },  	    

  	    setLanding( state, data ){
    		state.landing = data;
  	    },    

    },
}