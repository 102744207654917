/*
    Imports the API URL from the config.
*/
import { APP_CONFIG } from '../config.js';
import axios from 'axios'
import store from '../store'

export default {	      

    //get rambam
    getRambam: function(data){
        return axios.get(APP_CONFIG.API_URL+'/rambam'); 
    },     

    //get rambam demo guest mode
    getRambamDemo: function(data){
        return axios.get(APP_CONFIG.API_URL+'/demo'); 
    },    

    //get archived rambam 
    getArchiveRambam: function(data){
        return axios.get(APP_CONFIG.API_URL+`/archive/shiur/${data}`); 
    },      

    //get rambam
    submitDailyRambamQuiz: function(data){
        return axios.post(APP_CONFIG.API_URL+`/rambam`,data); 
    },     

    //submit demo quiz rambam
    submitDailyRambamQuizDemo: function(data){
        return axios.post(APP_CONFIG.API_URL+`/demo`,data); 
    },     

    //submit archive quiz rambam
    submitArchiveRambamQuiz: function(data){
        return axios.post(APP_CONFIG.API_URL+`/archive/quiz`,data); 
    },  

}