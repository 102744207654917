/*
    Imports the API URL from the config.
*/
import { APP_CONFIG } from '../config.js';
import axios from 'axios'
import store from '../store'

export default {	      

    forgotPasswordTokenRequest: function(data){
        return axios.post(APP_CONFIG.API_URL+`/forgot-password-token`, data); 
    },     

    passwordReset: function(data){
        return axios.post(APP_CONFIG.API_URL+`/password/reset`, data); 
    },      

}