<template>
	<div class="">
		<div class="w-full flex flex-col px-5 ">


			<div class="px-5 pt-5 pb-3 md:p-10 md:pb-6">
				

				<div class="justify-center overflow-hidden rounded-2xl md:rounded-4xl relative wrapper tablet:px-10 pb-8 md:pb-16" ref="register">
					<div class="p-8 px-12 overflow-hidden rounded-2xl md:rounded-4xl bg-r-lighter-blue">

						<div class="text-center">
				        	<input style="display: none;" ref="image" v-on:change="handleFileUpload()" type="file" data-name="avatar" accept=".jpg, .jpeg, .gif, .png, .svg">
							<figure v-on:click="fileInput" class="mt-0 h-32 w-32 mb-5 inline-block text-center image-uploader-container">
					            <img 
					            class="rounded-full overflow-hidden inline-block h-full w-full" 
					            ref="avatar-image" id="avatar-image"  
					            :src="url+registration.icon" 
				          		@error="imageLoadError">
					        </figure>
				        </div>

				        <div class="-mx-3 md:flex mb-6">

						    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
						      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="grid-first-name">
						        name
						      </label>
						      <input class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3" 
						      maxlength="30"
						      :class="{'border-danger' : error && !registration.name}" 
						      id="grid-first-name" type="text" placeholder="Name" 
						      v-model="registration.name">
						      <!-- <p class="text-red text-xs italic">Please fill out this field.</p> -->
						    </div>

						    <div class="md:w-1/2 px-3">
						      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="grid-last-name">
						        last name
						      </label>
						      <input class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4"
						      maxlength="30"
						      :class="{'border-danger' : error && !registration.surename}" 
						      id="grid-last-name" type="text" placeholder="Last Name" 
						      v-model="registration.surename">
						    </div>

						  </div>

						  <div class="-mx-3 md:flex mb-6">

						    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
						      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="grid-username">
						        username
						      </label>
						      <input class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3" 
						      :class="{'border-danger' : error && !registration.username}" 
						      id="grid-username" placeholder="Username" 
						      v-model="registration.username">
						      <!-- <p class="text-grey-dark text-xs italic">Make it as long and as crazy as you'd like</p> -->
						    </div>

						    <div class="md:w-1/2 px-3">
						      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="grid-password">
						        password
						      </label>
						      <input class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3" 
						      :class="{'border-danger' : error && !registration.password}" 
						      id="grid-password" type="password" placeholder="Password"
						      v-model="registration.password">
						      <!-- <p class="text-grey-dark text-xs italic">Make it as long and as crazy as you'd like</p> -->
						    </div>

						  </div>

						  <div class="-mx-3 md:flex mb-2">

						    <div class="md:w-1/2 px-3 mb-6">
						      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="grid-state">
						        grade
						      </label>
						      <div class="relative">
								<select id="grade" class="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" 
								v-model="registration.grade"
						      	>grade
									<option :value="grade" for v-for="(grade, index) in grades" :key="index">{{grade}}</option>
								</select>
						      </div>
						    </div>				    

						    <div class="md:w-1/2 px-3 mb-6">
						      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="grid-state">
						        boy / girl
						      </label>
						      <div class="relative">
						        <select class="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="grid-state" 
						        v-model="registration.gender"
						        :class="{'border-danger' : error && !registration.gender}" 
						        >
						          <option value="boys">boy</option>
						          <option value="girls">girl</option>
						        </select>
						      </div>
						    </div>

						  </div>

						  <div class="-mx-3 md:flex mb-6">

						    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
						      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="grid-parent_email">
						        parents email
						      </label>
						      <input class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3" 
						      :class="{'border-danger' : error && !registration.parent_email}" 
						      id="grid-parent_email" placeholder="Parents Email" 
						      v-model="registration.parent_email">
						      <!-- <p class="text-grey-dark text-xs italic">Make it as long and as crazy as you'd like</p> -->
						    </div>							    

						    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
						      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="grid-parent_email">
						        email
						      </label>
						      <input class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3"
						      id="grid-parent_email" placeholder="Email" 
						      v-model="registration.email">
						      <!-- <p class="text-grey-dark text-xs italic">Make it as long and as crazy as you'd like</p> -->
						    </div>	

					      </div>						  

					      <div class="-mx-3 md:flex mb-6">

						    <div class="px-3 mb-6 md:mb-0 flex-2">
						      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="grid-parent_email">
						        Address
						      </label>
						      <input class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3" 
						      :class="{'border-danger' : error && !registration.address}" 
						      id="grid-address" placeholder="Address" 
						      v-model="registration.address">
						      <!-- <p class="text-grey-dark text-xs italic">Make it as long and as crazy as you'd like</p> -->
						    </div>							    

						    <div class="px-3 mb-6 md:mb-0 flex-1">
						      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="grid-parent_email">
						        Country
						      </label>
						      <input class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3" 
						      :class="{'border-danger' : error && !registration.country}" 
						      id="grid-country" placeholder="Country" 
						      v-model="registration.country">
						      <!-- <p class="text-grey-dark text-xs italic">Make it as long and as crazy as you'd like</p> -->
						    </div>							    

					
					      </div>					      

					      <div class="-mx-3 md:flex mb-6">

						    <div class="px-3 mb-6 md:mb-0 flex-1">
						      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="grid-parent_email">
						        City
						      </label>
						      <input class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3" 
						      :class="{'border-danger' : error && !registration.city}" 
						      id="grid-city" placeholder="City" 
						      v-model="registration.city">
						      <!-- <p class="text-grey-dark text-xs italic">Make it as long and as crazy as you'd like</p> -->
						    </div>							    

						    <div class="px-3 mb-6 md:mb-0 flex-1">
						      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="grid-parent_email">
						        State
						      </label>
						      <input class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3" 
						      :class="{'border-danger' : error && !registration.state}" 
						      id="grid-state" placeholder="State" 
						      v-model="registration.state">
						      <!-- <p class="text-grey-dark text-xs italic">Make it as long and as crazy as you'd like</p> -->
						    </div>							    

							<div class="px-3 mb-6 md:mb-0 flex-1">
						      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="grid-parent_email">
						        Zip
						      </label>
						      <input class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3" 
						      :class="{'border-danger' : error && !registration.zip}" 
						      id="grid-zip" placeholder="Zip" 
						      v-model="registration.zip">
						      <!-- <p class="text-grey-dark text-xs italic">Make it as long and as crazy as you'd like</p> -->
						    </div>	
					
					      </div>	

					      <div class="-mx-3 md:flex mb-2">

						    <div class="w-full px-3 text-center inline-block mt-10">
								<button class="rounded-full bg-r-dark-orange hover:bg-r-lighter-orange active:bg-r-darker-orange px-4 md:w-48 h-10 md:h-12 shadow-c-xl-3 hover:shadow-dense-2 outline-none text-white text-xl md:text-1xl font-bold mb-3 uppercase" @click="submit">submit</button>		
						    </div>				    

						  </div>

					</div>
				</div>

				
			</div>


		</div>		

	</div>
</template>
<script>
	

	export default {
		data() {
			return {
            	url: '/assets/images/avatars/',
				default_image: 'default.png',
				error: false,
				registration: {
					name: "",
					surename: "",
					grade: "",
					gender: "",
					username: "",
					password: "",
					file: null,
					icon: 'default_image.png',
					address: '',
					zip: '',
					country: '',
					city: '',
					state: '',
				},
			}
		},
		computed: {

			appName: function () {
				return this.$store.getters.getApp.name;
			},	

			translations() {
				return this.$store.getters.getTranslations
			},

			userLoginStatus() {
				return this.$store.getters.getTokenLoadingStatus;
			},

			grades() {
				return this.$store.getters.getGrades
			},

		},

		methods: {

			imageLoadError: function () {
	  			event.target.src = this.url+this.default_image;
	  		},

	  		//handle image 
	  		handleFileUpload(){
	  			this.url = ""
	  		    this.registration.file = this.$refs.image.files[0];	
	  		    this.registration.icon = URL.createObjectURL(this.registration.file); 
	  		},

	  		fileInput: function () {
	  			this.$refs.image.click()
	  		},

	  		submit: function () {

				for(var i in this.registration){
					// console.log(i)
					if(this.registration[i] == "" || !this.registration[i]){
						if(i !== 'email' && i !== 'grade' && i !== 'file'){
							console.log(i)
							return this.error = true	
						}
					}
				}

		 		const user = new FormData();
				user.append('name', this.registration.name)
				user.append('family', this.registration.surename)
				user.append('grade', this.registration.grade)
				user.append('gender', this.registration.gender)
				user.append('username', this.registration.username)
				user.append('password', this.registration.password)
				user.append('parent_email', this.registration.parent_email)
				user.append('email', this.registration.email)
				user.append('file', this.registration.file)
				user.append('address', this.registration.address)
				user.append('country', this.registration.country)
				user.append('city', this.registration.city)
				user.append('state', this.registration.state)
				user.append('zip', this.registration.zip)

				this.$store.dispatch('registerUser', user).then(response => {
					console.log(response)
				  this.$store.commit('setToken', response.access_token)
				  this.$router.push({name:'home'})

				  this.$store.dispatch( 'setPopUp', 
					{
						header:response.header, 
						data:{
							message:response.message,
						},
						model: 'defualt',
						type:'message',
						emoji:'wink',
						error:false,
						without_buttons: false,
					});
					this.$store.dispatch('setPopUpStatus', 1);
		          				
		        }).catch(response => {

		          	this.$store.dispatch( 'setPopUp', 
					{
						header:response.header, 
						data:{
							message:response.message,
						},
						model: 'defualt',
						type:'message',
						emoji:'wink',
						error:false,
						without_buttons: false,
					});
					this.$store.dispatch('setPopUpStatus', 1);	
		          				
		        });
			},

		},	

		components: {
		},

		beforeCreate() {
			this.$store.dispatch('getGrades')
		}

	}
</script>