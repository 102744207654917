import ArchiveAPI from '../api/archive.js';

export const archive = {

    state: {

    	archive: {
    		meta:{
                total: 0, 
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 0,
                last_page: 0,
            },
    	},
    	archive_loading: 0,

    },

    getters: {

    	getArchive(state) {
            return state.archive;
        },    	

        getArchiveLoadingStatus(state) {
            return state.archive_loading;
        },        

    },
    
    actions: {           

        //get archive
        getArchive( { commit, dispatch}, data){

            commit( 'setArchiveLoadStatus', 1 );
            
            return new Promise((resolve, reject) => {

	            ArchiveAPI.getArchive(data)
	            .then( function( response ){
	                console.log(response.data)
	                commit( 'setArchive', response.data );
	                commit( 'setArchiveLoadStatus', 2 );
	                resolve(response.data)
	            })
	            .catch( function(error){
	                commit( 'setArchiveLoadStatus', 3 );
	                reject(error.response.data);
	            });

            });
        },           
      

    },

    mutations: {

	    setArchive( state, archive ){
            state.archive = archive;
	    },

	    setArchiveLoadStatus( state, status ){
      		state.archive_loading = status;
	    },     


    },
}