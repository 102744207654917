export const sidebar = {

    state: {

        sidebar_status: 0,

    },

    getters: {   	

        getSidebarStatus(state) {
            return state.sidebar_status;
        },        

    },
    
    actions: {           

        toggleSidebar( { commit, dispatch } ){

        	commit( 'setSidebarStatus', 1 );

        },          

        closeSidebar( { commit, dispatch }, data ){

        	commit( 'setSidebarStatus', 0 );

        },              


    },

    mutations: {


	    setSidebarStatus( state, status ){
	    	state.sidebar_status = status
	    },
   


    },
}