/*
    Imports the API URL from the config.
*/
import { APP_CONFIG } from '../config.js';
import axios from 'axios'
import store from '../store'

export default {	      

    //get grades
    preRegister: function(data){
        return axios.post(APP_CONFIG.API_URL+'/pre-register', data); 
    },  

}