import KeysAPI from '../api/keys.js';

export const keys = {

    state: {

    	keys: {
    		meta:{
                total: 0, 
                per_page: 10,
                from: 1,
                to: 0,
            },
        	keys_data:{earned:0,used:0}
    	},
    	keys_loading: 0,

    },

    getters: {

    	getKeys(state) {
            return state.keys;
        },    	

        getKeysLoadingStatus(state) {
            return state.keys_loading;
        },        

    },
    
    actions: {           

        //get keys
        getKeys( { commit, dispatch}, data){

            commit( 'setKeysLoadStatus', 1 );
            
            return new Promise((resolve, reject) => {

	            KeysAPI.getKeys(data)
	            .then( function( response ){
	                console.log(response.data)
	                commit( 'setKeys', response.data );
	                commit( 'setKeysLoadStatus', 2 );
	                resolve(response.data)
	            })
	            .catch( function(error){
	                commit( 'setKeysLoadStatus', 3 );
	                reject(error.response.data);
	            });

            });
        },           
      

    },

    mutations: {

	    setKeys( state, keys ){
            state.keys = keys;
	    },

	    setKeysLoadStatus( state, status ){
      		state.keys_loading = status;
	    },     


    },
}