/*
    Imports the API URL from the config.
*/
import { APP_CONFIG } from '../config.js';
import axios from 'axios'
import store from '../store'

export default {	      

    //get archive
    getArchive: function(data){
    	console.log(data)
        return axios.get(APP_CONFIG.API_URL+`/archive?PerPage=${data.PerPage}&&page=${data.Page}`); 
    },      

}