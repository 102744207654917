import UserAPI from '../api/user.js';
import router from '../routes.js'

export const user = {

    state: {
    	// user: {name: 'user', family: 'name', status: 'status', points_needed_to_next_status: 0, points: 0, points_spent: 0,},
        user: JSON.parse(localStorage.getItem('user')) || {name: 'user', family: 'name', points: 0, points_spent: 0,},
    	user_loading: 0,
        access_token: localStorage.getItem('access_token') || null,
        access_token_loading: 0,
        logout_load_status: 0,
    },

    getters: {

    	getUser(state) {
            return state.user;
        },    	

        getUserLoadingStatus(state) {
            return state.user_loading;
        },        

        getLogoutLoadingStatus(state) {
            return state.logout_load_status;
        },

        getToken(state) {
            return state.access_token
        },        

        getTokenLoadingStatus(state) {
            return state.access_token_loading
        },

        getLoggedIn(state) {

            if(!state.access_token || state.access_token == 'null' || state.access_token == 'undefined'){
                return false
            }
            return true
        },

    },
    actions: {           
        
        //register new user 
        registerUser({commit}, data) {
            
            commit( 'setUserLoadStatus', 1 );

            return new Promise((resolve, reject) => {

             UserAPI.registerUser(data)
             .then( function( response ){
                console.log(response.data)
                 commit( 'setUserLoadStatus', 2 );
                 resolve(response.data)
             })
             .catch( function(error){
                 commit( 'setUserLoadStatus', 3 );
                 reject(error.response.data);
             });
                

            })

        },

        //user login action - retreive token
        userLogin({commit, dispatch}, data) {
             
             commit( 'setTokenLoadStatus', 1 );

             return new Promise((resolve, reject) => {
                
                UserAPI.userLogin( data )
                 .then( function( response ){
                     commit( 'setToken',  response.data.access_token );
                     commit( 'setTokenLoadStatus', 2 );
                     resolve(response.data)
                     // dispatch( 'getUser' );
                 })
                 .catch( function(error){
                     // commit( 'setToken',  null );
                     commit( 'setTokenLoadStatus', 3 );
                     reject(error.response.data)
                 });

             })
             
        },        

        //user logout - remove token 
        userLogout({commit}) {
            
            commit( 'setLogoutLoadStatus', 1 );

             UserAPI.userLogout()
             .then( function( response ){
                 console.log(response)
                 commit( 'logout' );
                 router.push({ name: 'login' });
                 commit( 'setLogoutLoadStatus', 2 );
                 // commit( 'setToken',  response.data.access_token );
             })
             .catch( function(){
                 // commit( 'setTokenLoadStatus', 3 );
                 commit( 'logout' );
                 router.push({ name: 'login' });
                 commit( 'setLogoutLoadStatus', 3 );
             });
        },

        //logout - remove token 
        localLogout({commit}) {
             commit( 'logout' );
             router.push({ name: 'login' });
        },

        //get user data
        getUser( { commit } ){

            commit( 'setUserLoadStatus', 1 );
            
            return new Promise((resolve, reject) => {

                UserAPI.getUserData()
                .then( function( response ){
                    console.log(response)
                    commit( 'setUser', response.data.data );
                    commit( 'setUserLoadStatus', 2 );
                    resolve(response.data)

                })
                .catch( function(error){
                    commit( 'setUserLoadStatus', 3 );
                    reject(error.response)
                }); 
                               
            })
        },      


    },

    mutations: {

	    setUserLoadStatus( state, status ){
      		state.user_loading = status;
	    },

	    setUser( state, user ){
            localStorage.setItem('user', JSON.stringify(user));
      		state.user = user;
	    },

        setToken( state, token) {
            state.access_token = token;
            localStorage.setItem('access_token', token)
        },

        setTokenLoadStatus( state, status) {
            state.access_token_loading = status;
        },         
        
        setLogoutLoadStatus( state, status ){
            state.logout_load_status = status;
        },

        logout( state, ) {
            state.access_token = null;
            state.cart = [];
            state.user = {name: 'user', family: 'name', points: 0, points_spent: 0,};
            localStorage.removeItem('access_token')
            localStorage.removeItem('cart')
            localStorage.removeItem('user')
            localStorage.removeItem('products')
        },       

    },
}