<template>
	<div class="">
		<div class="absolute w-full flex flex-col px-5 mt-12">
			<div class="px-5 pt-5 pb-3 md:p-10 md:pb-6 shadow-c-xl-2 rounded-4xl mx-auto bg-rambam-yellow-orange border-2 md:border text-center">
				<div class="inline-block md:mb-3">
					<img class="h-12 md:h-20 lg:h-24 p-4" src="/assets/images/logo/main_logo.png" alt="logo" @click="route('landing')">
				</div>
				<div class="flex flex-col justify-center items-center mt-5 font-Alef md:w-30r">
					<div class="w-full mb-2 md:mb-6">
						<input class="w-full h-12 md:h-16 text-base md:text-1xl text-r-dark-blue px-4 rounded-xl md:rounded-1xl bg-white focus:border-r-dark-orange border-2 md:border shadow-c-xl-2 outline-none" 
						:class="{'border-danger' : error && username == ''}"
						v-model="username"
						type="text" placeholder="username">
						<span class="text-red-600 block text-left mt-2 font-bold" v-if="error && username == ''">* Username is required</span>
					</div>
					<div class="w-full mb-5 md:mb-10">
						<input class="w-full h-12 md:h-16 text-base md:text-1xl text-r-dark-blue px-4 rounded-xl md:rounded-1xl bg-white focus:border-r-dark-orange border-2 md:border shadow-c-xl-2 outline-none" 
						:class="{'border-danger' : error && password == ''}"
						v-model="password"
						v-on:keyup.enter="login"
						type="password" placeholder="password">
						<span class="text-red-600 block text-left mt-2 font-bold" v-if="error && password == ''">* Password is required</span>
					</div>
					<button class="rounded-full bg-r-dark-orange hover:bg-r-lighter-orange active:bg-r-darker-orange w-24 md:w-56 h-10 md:h-16 shadow-c-xl-3 hover:shadow-dense-2 outline-none text-white text-base md:text-2xl font-bold mb-3 uppercase"
					@click="login">login</button>
					<p class="text-r-dark-blue mt-6 mb-3 underline cursor" @click="forgotPassword">Forgot Password?</p>
				</div>
				<p class="text-r-dark-blue mt-6 mb-3 underline cursor" @click="register">{{translations.register_account}}</p>
			</div>
		</div>		

	</div>
</template>
<script>


	export default {
		data() {
			return {
				username: '',
				password: '',
				error: false,
			}
		},
		computed: {

			appName: function () {
				return this.$store.getters.getApp.name;
			},	

			translations() {
				return this.$store.getters.getTranslations
			},

			userLoginStatus() {
				return this.$store.getters.getTokenLoadingStatus;
			},
		},

		methods: {

			login: function () {
				if(!this.username || !this.password){return this.error = true}
				this.$store.dispatch( 'userLogin' , {username:this.username,password:this.password}).catch(response => {

				  	this.$store.dispatch( 'setPopUp', 
					{
						header:response.header, 
						data:{
							message:response.message,
						},
						model: 'defualt',
						type:'message',
						emoji:'wink',
						error:true,
						without_buttons: false,
					});
					this.$store.dispatch('setPopUpStatus', 1);  	

		        });
			},

			register: function () {
				this.$router.push({name:"register"})
			},

			forgotPassword: function() {
				this.$router.push({name:'forgot-password'})
			},

			route: function (route) {
				this.$router.push({name:route})
			}

		},	

		beforeMount() {
      		this.$store.dispatch( 'getTranslations' );
		},

		watch: {

		    userLoginStatus: {
		      // immediate: true,
		        deep: true,
		        handler(newValue, oldValue) {
		          if(newValue === 2){
		          	this.$router.push({name: 'home'})
		          }
		        }
		    },
		     
		},
	}
</script>