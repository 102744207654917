/*
|-------------------------------------------------------------------------------
| routes.js
|-------------------------------------------------------------------------------
| Contains all of the routes for the application
*/

/*
    Imports Vue and VueRouter to extend with the routes.
*/
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store.js';

/*
	Landing Page
*/
import BasicLayout from './layouts/BasicLayout.vue';
import Landing from './pages/Landing.vue';
import Login from './pages/Login.vue';
import ForgotPassword from './pages/ForgotPassword.vue';
import PasswordReset from './pages/PasswordReset.vue';
import Register from './pages/Register.vue';
import Home from './pages/Home.vue';
import DailyRambam from './pages/DailyRambam.vue';
import DailyRambamQuiz from './pages/DailyRambamQuiz.vue';
import Keys from './pages/Keys.vue';
import Points from './pages/Points.vue';
import Demo from './pages/Demo.vue';
import DailyRambamQuizDemo from './pages/DailyRambamQuizDemo.vue';
import Archive from './pages/Archive.vue';
import ArchiveShiur from './pages/ArchiveShiur.vue';
import ArchiveShiurQuiz from './pages/ArchiveShiurQuiz.vue';
// import Test from './pages/Test.vue';


/*
    Extends Vue to use Vue Router
*/
Vue.use( VueRouter )

export default new VueRouter({


mode: 'history',
scrollBehavior() {
    return { x: 0, y: 0 };
  },
	routes: [
		{
		path: '/',
		component: BasicLayout,
		// meta: { requiresGuest : true, },

		children : [
				{
					name: 'landing',
					path: '/', 
					component: Landing,
				},					
				{
					name: 'login',
					path: '/login', 
					component: Login,
					meta: { requiresGuest : true, },
				},				
				{
					name: 'forgot-password',
					path: '/forgot-password', 
					component: ForgotPassword,
					meta: { requiresGuest : true, },
				},					
				{
					name: 'password/reset',
					path: '/password/reset/:token?', 
					component: PasswordReset,
					meta: { requiresGuest : true, },
				},					
				{
					name: 'register',
					path: '/register', 
					component: Register,
					meta: { requiresGuest : true, },
				},					
				{
					name: 'home',
					path: '/home', 
					component: Home,
					// meta: { requiresAuth : true, },
				},					
				{
					name: 'daily-rambam',
					path: '/daily-rambam', 
					component: DailyRambam,
					meta: { requiresAuth : true, },
				},					
				{
					name: 'daily-rambam-quiz',
					path: '/daily-rambam-quiz', 
					component: DailyRambamQuiz,
					meta: { requiresAuth : true, },
				},					
				{
					name: 'keys',
					path: '/keys/:page?', 
					props: (route) => ({ page: route.params.page || 1 }),
					component: Keys,
					meta: { requiresAuth : true, },
				},					
				{
					name: 'points',
					path: '/points/:page?', 
					props: (route) => ({ page: route.params.page || 1 }),
					component: Points,
					meta: { requiresAuth : true, },
				},					
				{
					name: 'archive',
					path: '/archive/:page?', 
					props: (route) => ({ page: route.params.page || 1 }),
					component: Archive,
					// meta: { requiresAuth : true, },
				},					
				{
					name: 'archive-rambam',
					path: '/archive/shiur/:shiur', 
					component: ArchiveShiur,
					// meta: { requiresAuth : true, },
				},					
				{
					name: 'archive-rambam-quiz',
					path: '/archive/quiz/:shiur', 
					component: ArchiveShiurQuiz,
					// meta: { requiresAuth : true, },
				},					
				{
					name: 'demo',
					path: '/demo', 
					component: Demo,
				},	
				{
					name: 'daily-rambam-quiz-demo',
					path: '/daily-rambam-quiz-demo', 
					component: DailyRambamQuizDemo,
				},			
				// {
				// 	name: 'test',
				// 	path: '/test/:page?', 
				// 	props: (route) => ({ page: route.params.page || 1 }),
				// 	component: Test,
				// 	meta: { requiresAuth : true, },
				// },					

			],	
		},	
	]
});