<template>
	<div class="">
		<div class="w-full flex flex-col px-5 ">


			<div class="mobile:px-0 px-5 pt-5 pb-3 md:p-10 md:pb-6">
				
				<h1 class="text-r-dark-orange font-serif font-bold mobile:text-1xl text-3xl uppercase mb-5 text-center">shiur #{{rambam.shiur}}</h1>

				<div class="justify-center overflow-hidden mobile:rounded-lg rounded-2xl relative wrapper tablet:px-10 pb-8 md:pb-16" ref="rambam-video">
					<div class="videoWrapper overflow-hidden mobile:rounded-lg rounded-2xl bg-green-200">

						<video-player v-if="loaded" @ended="ended" :options="videoOptions"/>

						<!-- <iframe class="overflow-hidden rounded-4xl" width="700" height="455" src="youtube.com?rel=0'" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>	 -->
					</div>
				</div>

				<div class="overflow-hidden mobile:rounded-2xl rounded-4xl relative wrapper mobile:px-5 mobile:py-5 tablet:px-10 py-10 px-12 bg-r-lighter-blue" style="margin-bottom: 3rem" ref="rambam-text" v-for="mitzva in rambam.rambam_text">
					<div class="flex mb-5 mobile:flex-col">
						<div class="mobile:h-24 h-40 mobile:w-24 w-40 bg-light-blue flex-shrink-0 text-center px-1 mobile:py-3 py-4 mr-4">
							<span class="block mobile:text-lg mobile:mb-1 text-3xl font-Alef leading-none">{{mitzva.type, translations | mitzvaFilter}}</span>
							<span class="block mobile:text-2xl text-5xl font-thin font-Montserrat">#{{mitzva.mitzva_id}}</span>
						</div>
						<div class="w-full">
							<h1 class="italic font-sans mobile:text-base text-2xl mobile:mt-5 mb-5 font-serif">
								<img src="/assets/images/assets/daily_rambam/TorahIcon.png" class="h-8 image inline-block" alt="">
								We learn this mitzva from parshas {{mitzva.parsha}}:</h1>
							<p class="text-black mobile:text-2xl text-5xl font-David font-medium pre-white text-right mobile:leading-8 leading-10" style="direction: rtl;">{{mitzva.posuk}}</p>	
						</div>
					</div>
					<div>
						<p class="text-black mobile:text-base text-2xl font-serif font-light pre-white text-justify leading-9 mobile:leading-6 mobile:mb-8">{{mitzva.summary}}</p>
						<p class="text-black mobile:text-sm text-1xl font-Montserrat font-medium pre-white md:text-justify leading-9 mobile:leading-6 mobile:text-sm mt-4" v-if="mitzva.learn_more"><img src="/assets/images/assets/daily_rambam/SeforimIcon.png" class="h-8 image inline-block mr-3" alt="">The details of this mitzvah are explained in Mishnayos & Gemara: <br><span class="font-bold leading-none">{{mitzva.learn_more}}</span></p>
					</div>
					
				</div>

				<div class="wrapper text-right mobile:pt-4 pt-12">
					<!-- <button class="mt-5 h-20 w-20 rounded-1xl bg-rambam-darker-yellow-orange hover:bg-r-lighter-orange active:bg-r-darker-orange outline-none">
						<span class="text-white font-bold uppercase text-xl">{{translations.start_quiz}}</span>
					</button> -->
					<button class="mobile:mt-2 mt-5 mobile:h-12 h-20 px-4 rounded-1xl bg-rambam-darker-yellow-orange hover:bg-r-lighter-orange active:bg-r-darker-orange outline-none" @click="route('archive-rambam-quiz')">
						<span class="text-white font-bold uppercase text-xl mobile:text-base">{{translations.start_quiz}}</span>
					</button>
				</div>
				<!-- <p class="text-r-dark-blue mt-2 underline cursor" @click="">{{translations}}</p> -->
			</div>


		</div>		

	</div>
</template>
<script>
	
	import VideoPlayer from "../components/VideoPlayer.vue";

	export default {
		data() {
			return {
            	loaded: false,
	            watched: false,
				video_url: '/assets/videos/',
				videoOptions: {
					autoplay: true,
					controls: true,
					fill: true,
					aspectRatio: '16:9',
					sources: [
						{
							src:
								"",
							  type: "video/mp4"
						}
					]
				},
			}
		},
		computed: {

			appName: function () {
				return this.$store.getters.getApp.name;
			},	

			translations() {
				return this.$store.getters.getTranslations
			},

			userLoginStatus() {
				return this.$store.getters.getTokenLoadingStatus;
			},

			rambam() {
				return this.$store.getters.getRambam.data
			},

			shiurId() {
				return parseInt(this.$route.params.shiur)
			}
		},

		methods: {

			ended: function () {
				this.watched = true
				var element = this.$refs['rambam-text'];
			    var top = element.offsetTop;

			    window.scrollTo(0, top);
			},

			route: function (route) {
				
				this.$router.push({name:route})
			}

		},	

		filters: {

	      	mitzvaFilter: function (value, trans) {
		       	switch(value){
		            case 'N':
		              return trans.mitzva_types.negative
		            break;            
		            case 'P':
		              return trans.mitzva_types.positive
		            break;	            
		        }
			},
		},

		components: {
			VideoPlayer,
		},

		beforeMount() {
      		this.$store.dispatch( 'getArchiveRambam', this.shiurId ).catch(response => {
      			// this.$router.push({name:'home'})
      			
      			this.$store.dispatch( 'setPopUp', 
				{
					header:response.header, 
					data:{
						message:response.message,
					},
					model: 'defualt',
					type:'message',
					emoji:'danger',

				});
				this.$store.dispatch('setPopUpStatus', 1);
      		})
		},

		beforeDestroy() {
			this.$store.dispatch('resetDailyRambam');
	    },

		watch: {

		    rambam: {
		      // immediate: true,
		        deep: true,
		        handler(newValue, oldValue) {
		        	console.log(newValue.main_video)
		        	this.videoOptions.sources[0].src = this.video_url+newValue.main_video
		        	this.loaded = true
		        }
		    },
		     
		},

	}
</script>