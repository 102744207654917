/*
  Adds the promise polyfill for IE 11
*/
require('es6-promise').polyfill();

/*
    Imports Vue and Vuex
*/
import Vue from 'vue'
import Vuex from 'vuex'

/*
    Initializes Vuex on Vue.
*/
Vue.use( Vuex )

/*
  Import our data modules.
*/
import { user } from './modules/user.js'
import { auth } from './modules/auth.js'
import { loading } from './modules/loading.js'
import { app } from './modules/app.js'
import { settings } from './modules/settings.js'
import { register } from './modules/register.js'
import { contact } from './modules/contact.js'
import { landing } from './modules/landing.js'
import { rambam } from './modules/rambam.js'
import { popup } from './modules/popup.js'
import { sidebar } from './modules/sidebar.js'
import { keys } from './modules/keys.js'
import { points } from './modules/points.js'
import { fuel } from './modules/fuel.js'
import { counter } from './modules/counter.js'
import { archive } from './modules/archive.js'


/*
  Exports our data store.
*/
export default new Vuex.Store({

    modules: {
    	user,
      auth,
      loading,
      app,
      settings,
      register,
      contact,
      landing,
      rambam,
      popup,
      sidebar,
      keys,
      points,
      counter,
      fuel,
      archive,
    },
    
});