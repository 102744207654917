<style lang="scss" scoped="true">
	.fab-container{
	    position: fixed;
	    right: 26px;
	    bottom: 33px;
	    z-index: 2000;
	    transition: color .2s ease-in-out;
	    -webkit-animation-duration: 1.5s;
	    animation-duration: 1.5s;
   }
    .fab-button{

      background: #2d53fe;
      color: #fff;
      text-align: center;
      line-height: 60px;
      font-size: 58px;
      width: 7.5rem;
	  height: 7.5rem;
      border-radius: 100%;
      cursor: pointer;
      box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,.1), 0 0.9375rem 1.40625rem rgba(90,97,105,.1), 0 0.25rem 0.53125rem rgba(90,97,105,.12), 0 0.125rem 0.1875rem rgba(90,97,105,.1);
      
        &:hover {
          box-shadow:  0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.42), 0 0.9375rem 1.40625rem rgba(90,97,105,.1), 0 0.25rem 0.53125rem rgba(90,97,105,.12), 0 0.125rem 0.1875rem rgba(90,97,105,.1);
        }

		@media only screen and (max-width: 768px) {
            width: 4.5rem;
      		height: 4.5rem;
        }

        @media only screen and (max-width: 480px) {
          width: 55px;
          height: 55px;
        }        

        
    }
</style>
<template>
	<div class="fab-container pt-3" v-tooltip="translations.fuel_tooltip" v-if="loggedIn">
		<div class="fab-button">
			<img :src="url+'fuel_'+fuel.sum+'.png'" alt="">
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				url : '/assets/images/assets/fuel/',
				// fuel: 3,
				oldValue: 3,
				newValue: 4,
			}
		},

		computed: {

			fuel() {
				return this.$store.getters.getFuel
			},

			translations() {
				return this.$store.getters.getTranslations
			},

			loggedIn() {
				return this.$store.getters.getLoggedIn
			}
		
		},

		methods: {
			flicker: function () {
				console.log(this.oldValue)
    			
    			var _this = this;

				setInterval(function() {
					switch(_this.fuel){
			            case _this.oldValue:
			              _this.fuel = _this.newValue
			            break;            
			            case _this.newValue:
			             _this.fuel = _this.oldValue
			            break;	            
			        }
				}, 700);
			}
		},

		mounted() {
			this.$store.dispatch('getFuel')
		},

		
	}
</script>