<template>
    <div>
        <video style="width: 100%;"  @ended="ended" ref="videoPlayer" class="video-js vjs-theme-sea"></video>
    </div>
</template>

<script>
import videojs from 'video.js';

export default {
    name: "VideoPlayer",
    props: {
        options: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            player: null
        }
    },
    mounted() {
        this.setup()
    },
    methods: {
    	ended: function () {
    		return this.$emit('ended')
    	},

    	setup: function () {
	        this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
	            console.log('onPlayerReady', this);
	        })
    	},
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose()
        }
    }
}
</script>