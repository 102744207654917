import FuelAPI from '../api/fuel.js';

export const fuel = {

    state: {

    	fuel: {sum:0},
    	fuel_loading: 0,

    },

    getters: {

    	getFuel(state) {
            return state.fuel;
        },    	

        getFuelLoadingStatus(state) {
            return state.fuel_loading;
        },        

    },
    
    actions: {           

        //get fuel
        getFuel( { commit, dispatch}, data){

            commit( 'setFuelLoadStatus', 1 );
            
            return new Promise((resolve, reject) => {

	            FuelAPI.getFuel(data)
	            .then( function( response ){
	                console.log(response.data)
	                commit( 'setFuel', response.data );
	                commit( 'setFuelLoadStatus', 2 );
	                resolve(response.data)
	            })
	            .catch( function(error){
	                commit( 'setFuelLoadStatus', 3 );
	                reject(error.response.data);
	            });

            });
        },  

        setFuel({commit}, data){
        	commit( 'setFuel', data );
            commit( 'setFuelLoadStatus', 2 );
        }         
      

    },

    mutations: {

	    setFuel( state, fuel ){
            state.fuel = fuel;
	    },

	    setFuelLoadStatus( state, status ){
      		state.fuel_loading = status;
	    },     


    },
}