<style lang="scss">
	.grid-container{
	    display: grid;
	    grid-template-columns: repeat(auto-fit, minmax(6rem, max-content));
	    justify-content: center;
	    grid-gap: 1rem;
	    row-gap: 1rem;
	    justify-items: center;

	    @media only screen and (min-width: 1024px) {
	        grid-template-columns: repeat(auto-fit, minmax(6rem, max-content));
	        column-gap: 3rem;
	    }
	}

</style>
<template>

	<div class="">
		
		<div class="w-full flex flex-col px-5 ">


			<div class="mobile:px-0 px-5 pb-3 md:px-10 md:pb-6">
				
				<div class="wrapper">				
					
					<div class="relative flex justify-between px-12 ">
						<!-- <button @click="previous" v-if="!firstPage" class="mobile:mt-2 mt-5 mobile:h-12 mobile:w-12 h-20 w-20 px-4 rounded-1xl bg-rambam-darker-yellow-orange hover:bg-r-lighter-orange active:bg-r-darker-orange outline-none">
							<img src="/assets/svg/previous.svg" class="image h-8 block mx-auto" alt="" >
						</button>
						<button @click="next" v-if="!lastPage" class="mobile:mt-2 mt-5 mobile:h-12 mobile:w-12 h-20 w-20 px-4 rounded-1xl bg-rambam-darker-yellow-orange hover:bg-r-lighter-orange active:bg-r-darker-orange outline-none">
							<img src="/assets/svg/next.svg" class="image h-8 block mx-auto" alt="" >
						</button> -->	
					</div>


					<div class="rounded-2xl md:rounded-4xl bg-r-lighter-blue relative mt-16">

						<div class="-mt-10 w-full mobile:h-16 md:h-20 absolute text-center">
							<div class="mx-auto relative rounded-2xl h-full inline-block">
								<div class="mobile:text-lg text-2xl text-r-dark-orange font-bold flex-shrink-0 mx-4 p-4 px-6 bg-rambam-yellow-orange rounded-2xl uppercase">Archive</div>
							</div>
						</div>
						
						<div class='items-center flex-col flex h-full w-full pt-5 pb-10 mobile:px-3 md:px-12'>

							<!-- personal rank div -->
							<div class="w-full m-10 md:mx-24">
								<div class="h-full w-full right-0 rounded-l-4xl mobile:px-4 px-10 flex items-center" >

							

									<div class="w-full flex flex-wrap justify-between items-center mobile:justify-center">

										<div class="mobile:text-center">
											<h1 class="mobile:text-base text-2xl text-r-dark-orange font-bold text-ellipsis mobile:mr-0 mr-5">{{translations.archive_instructions}} </h1>									
									
										</div>
										
									</div>
									
								</div>
							</div>

							<div class='flex justify-center items-center overflow-hidden w-full overflow-hidden rounded-2xl md:rounded-4xl bg-light-blue relative py-6 px-12'>

								<div class="grid-container w-full py-4 ">
																		
									<div class="w-20 h-20 my-5 md:my-3 cursor-pointer relative inline-block cursor-pointer" 
									v-for="(shiur, index) in shiurim.data" :key="index" @click="getArchivedShiur(shiur.shiur)">
										<span class="absolute font-Alef font-bold text-1xl rounded-full h-10 w-10 py-1 px-3 text-white bg-r-green block flex justify-center items-center shadow-dense-green-2 -mt-5 -ml-5" v-if="shiur.completed"><i class="material-icons"  style="font-weight: bold">done</i>
										</span>								

										
										<div class="text-center rounded-1xl overflow-hidden h-full bg-rambam-darker-yellow-orange justify-center px-1 block text-center">

											
												<span class="text-white font-bold uppercase mobile:text-sm text-base block pt-3">shiur</span>
												<span class="text-white font-bold uppercase mobile:text-xl text-2xl block ">#{{shiur.shiur}}</span>
											
										</div>
									</div>	

								</div>

						        
						    </div>	

						    <div class="mt-4 w-full overflow-x-scroll">
							    <vue-pagination :pagination="shiurim.meta" :color="'orange'"
		               			@paginate="getPage()"
		               			:offset="2">
			            		</vue-pagination>	
						    </div>
						    

						</div>

					</div>
				
				</div>

			</div>

		</div>		

	</div>



  
</template>
<script>

import VuePagination from '../components/VuePagination.vue';

export default {
  data () {
    return {
      default: '/assets/images/avatars/default.png',
      url: '/assets/images/avatars/',
    }
  },
  computed: {
  	shiurim() {
  		return this.$store.getters.getArchive
  	},

  	translations() {
  		return this.$store.getters.getTranslations
  	}
  },

  components: {
  	VuePagination,
  },

  methods: {
    getPage: function () {
		this.$store.dispatch( 'getArchive', {PerPage:30, Page: this.shiurim.meta.current_page } );
		this.$router.push({name:'archive', params:{page:this.shiurim.meta.current_page}})
	},

	getArchivedShiur(shiur) {
        this.$router.push({name: 'archive-rambam', params: {shiur: shiur}})
	},

  },

  beforeMount() {
  	this.$store.dispatch('getArchive', {PerPage:30, Page: parseInt(this.$route.params.page)})
  }
}
</script>