<template>
	<div id="basic-layout" style="position: relative;">
		
		<loader></loader>
		
		<Sidebar></Sidebar>
		
		<pop-up></pop-up>

		<header class="bg-light-blue">
			<div class="mx-3 sm:mx-5 md:mx-16 lg:mx-16 flex justify-between h-24 items-center">
	
				<div class="text-white flex navbar-icon cursor-pointer block" @click="navbar()" v-if="loggedIn">
					<i class="material-icons nav-icon">
					menu
					</i>
				</div>
				
				<span v-if="pageName == 'login' && !loggedIn"></span>
				<a href="/login" v-if="!loggedIn && pageName != 'login'" class="flex items-center text:base md:text-xl font-medium text-r-dark-orange hover:text-r-lighter-orange active:text-r-darker-orange  uppercase"><i class="material-icons nav-icon text-sm pr-2" style="font-size: 30px !important;" @click.prevent="route('login')">account_circle</i><span>Login</span></a>

				<img @click="route('home')" class="h-10" src="/assets/images/logo/main_logo.png" alt="logo">
			</div>
		</header>

		<div class="bg-light-blue min-h-screen flex flex-col mobile:pt-0 pt-5 relative">
			
			<div class="absolute w-full h-64 bg-r-lighter-blue mt-64 mobile:hidden tablet:hidden" v-if="pageName != 'daily-rambam-quiz'"></div>
			<!-- page wrapper -->
			<div class="h-full">
				
				<router-view></router-view>
			
			</div>
			
			<div class="w-full h-64 bg-r-lighter-blue mt-20"></div>
			
			<fuel-fab v-if="loggedIn"></fuel-fab>
			<counter-fab v-if="loggedIn"></counter-fab>

		</div>

		

	</div>
</template>
<script>

	import Loader from '../components/Loader.vue';
	import PopUp from '../components/MessagePopup.vue';
	import Sidebar from '../components/Sidebar.vue';
	import FuelFab from '../components/Fuel.vue';
	import CounterFab from '../components/613CounterFab.vue';

	export default {
		components: {
			Loader,
			PopUp,
			Sidebar,
			FuelFab,
			CounterFab
		},

		computed: {
			pageName(){
				return this.$route.name
			},

			loggedIn() {
				return this.$store.getters.getLoggedIn
			}
		},

		methods: {
			home() {
				return this.$router.push({name:'landing'})
			},

			navbar: function () {
				this.$store.dispatch('toggleSidebar');
			},

			route(route) {
				return this.$router.push({name:route})
			}
		},

		beforeMount() {
      		this.$store.dispatch( 'getTranslations' );
		},
	}
</script>