<template>
	<div class="">
		<div class="absolute w-full flex flex-col px-5 mt-12">
			<div class="px-5 pt-5 pb-3 md:p-10 md:pb-6 shadow-c-xl-2 rounded-4xl mx-auto bg-rambam-yellow-orange border-2 md:border text-center">
				<div class="inline-block md:mb-3 mobile:hidden">
					<img class="h-12 md:h-20 lg:h-24 p-4" src="/assets/images/logo/main_logo.png" alt="logo" @click="route('landing')">
				</div>
				<div class="flex flex-col justify-center items-center mt-5 font-Alef md:w-30r">
					<div class="w-full mb-4 md:mb-6">
						<span class="text-3xl block text-left mb-2 font-bold">Password Reset</span>
						<span class="text-lg block text-left mb-3 font">{{translations.pages.password_reset.reset_instructions}}</span>
						<span class="text-xl block text-left my-1 font-bold">New Password</span>

						<div class="mb-3">
							<input class="w-full h-12 md:h-16 text-base md:text-1xl text-r-dark-blue px-4 rounded-xl md:rounded-1xl bg-white focus:border-r-dark-orange border-2 md:border shadow-c-xl-2 outline-none" 
							:class="{'border-danger' : error && password == ''}"
							v-model="password"
							type="text" placeholder="">	
							<span class="text-red-600 block text-left mt-2 font-bold" v-if="error && password == ''">* Password is required</span>	
						</div>
						

						<span class="text-xl block text-left my-1 font-bold">Confirm New Password</span>
						<input class="w-full h-12 md:h-16 text-base md:text-1xl text-r-dark-blue px-4 rounded-xl md:rounded-1xl bg-white focus:border-r-dark-orange border-2 md:border shadow-c-xl-2 outline-none" 
						:class="{'border-danger' : error && password_confirm == ''}"
						v-model="password_confirm"
						type="text" placeholder="">
						<span class="text-red-600 block text-left mt-2 font-bold" v-if="error && password_confirm == ''">* Password Confirmation is required</span>
						<span class="text-red-600 block text-left mt-2 font-bold" v-if="!passwordMatch">* Passwords do not match</span>
					</div>
					<button class="rounded-full bg-r-dark-orange hover:bg-r-lighter-orange active:bg-r-darker-orange w-24 md:w-56 h-10 md:h-16 shadow-c-xl-3 hover:shadow-dense-2 outline-none text-white text-base md:text-2xl font-bold mb-3 uppercase"
					@click="passwordReset">Reset</button>
				</div>
				<p class="text-r-dark-blue mt-3 md:mt-6 mb-3 underline cursor" @click="forgotPassword">Forgot Password?</p>
			</div>
		</div>		

	</div>
</template>
<script>


	export default {
		data() {
			return {
				password: '',
				password_confirm: '',
				error: false,
			}
		},
		computed: {

			appName: function () {
				return this.$store.getters.getApp.name;
			},	

			translations() {
				return this.$store.getters.getTranslations
			},

			token() {
				return this.$route.params.token;
			},			

			username() {
				return this.$route.query.username;
			},

			passwordMatch() {
				//if passwords are empty return no error
				if(!this.password || this.password == '' || !this.password_confirm || this.password_confirm == '')
				{
					return true
				}
				// if passwords are not empty and they dont match return error
				if(this.password !== this.password_confirm){
					return false
				}else{
					return true
				}
			}
		},

		methods: {

			passwordReset: function () {
				if(!this.password || this.password == '' || !this.password_confirm || this.password_confirm == ''){return this.error = true}

				if(!this.passwordMatch){return}

				var data = {
					username:this.username,
					password:this.password,
					password_confirm:this.password_confirm,
					token:this.token
				}

				this.$store.dispatch( 'passwordReset' , data).then(response => {

				  	this.$store.dispatch( 'setPopUp', 
					{
						header:response.header, 
						data:{
							message:response.message,
						},
						model: 'defualt',
						type:'message',
						emoji:'wink',
						error:true,
						without_buttons: false,
						return_route: 'login',
					});
					this.$store.dispatch('setPopUpStatus', 1);  	

		        }).catch(response => {

				  	this.$store.dispatch( 'setPopUp', 
					{
						header:response.header, 
						data:{
							message:response.message,
						},
						model: 'defualt',
						type:'message',
						emoji:'wink',
						error:true,
						without_buttons: false,
					});
					this.$store.dispatch('setPopUpStatus', 1);  	

		        });
			},

			forgotPassword: function () {
				this.$router.push({name:"forgot-password"})
			},

			route: function (route) {
				this.$router.push({name:route})
			}

		},	

		beforeMount() {
      		this.$store.dispatch( 'getTranslations' );
		},

	}
</script>