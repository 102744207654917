import AppAPI from '../api/app.js';

export const app = {

    state: {
        app: JSON.parse(localStorage.getItem('app')) || {name: 'app'},
        logos: JSON.parse(localStorage.getItem('logos')) || {main_logo:'default_logo.png', store_logo:'default_logo.png', print_logo:'default_logo.png', admin_logo:'default_logo.png'},
    	app_loading: 0,
    },

    getters: {

    	getApp(state) {
            return state.app;
        },      

        getLogos(state) {
            return state.logos;
        },    	

        getAppLoadingStatus(state) {
            return state.app_loading;
        },

    },
    actions: {   

        //get app data
        getAppData({commit, dispatch}, data) {
             
             commit( 'setAppLoadStatus', 1 );

             AppAPI.getAppData( data )
             .then( function( response ){
                 dispatch( 'getAppLogos' )
                 commit( 'setApp', response.data )
                 commit( 'setAppLoadStatus', 2 );

             })
             .catch( function(){
                 commit( 'setAppLoadStatus', 3 );
             });
        },        

        //get app logos
        getAppLogos({commit, dispatch}) {
             
             commit( 'setAppLoadStatus', 1 );

             AppAPI.getAppLogos( )
             .then( function( response ){
                 commit( 'setAppLogos', response.data )
                 commit( 'setAppLoadStatus', 2 );

             })
             .catch( function(){
                 commit( 'setAppLoadStatus', 3 );
             });
        },              

    },

    mutations: {

	    setAppLoadStatus( state, status ){
      		state.app_loading = status;
	    },

	    setApp( state, app ){
      		state.app = app;
            localStorage.setItem('app', JSON.stringify(app));
	    },     

        setAppLogos( state, logos ){
            state.logos = logos;
            localStorage.setItem('logos', JSON.stringify(logos));
        },
    

    },
}