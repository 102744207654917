<style>
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
<template>
	<div class="w-full">
		
		<!-- promo video placeholder -->
		<div class="rounded-xl justify-center relative wrapper tablet:px-10 pb-16">
			<div class="videoWrapper bg-green-200">
				<iframe width="700" height="455" :src="pageData.main_video_link+'?rel=0'" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>	
			</div>
			
		</div>

		<div class="w-full bg-r-purple">
			
			<div class="flex justify-between items-center wrapper mobile:pt-8 mobile:pb-8 pb-16 pt-16 tablet:flex-col mobile:flex-col">
				
				<div class="bg-lighter-blue laptop:my-10 mobile:mx-0 mobile:mt-10 tablet:mx-0 tablet:mt-5 mobile:px-5 tablet:px-10" style="max-width: 540px;">
					<img class="image h-full w-full" src="/assets/images/assets/landing_cutout.png" alt="">
				</div>

				<div class="flex-1 my-10 mobile:px-5 tablet:px-10 laptop:ml-2 laptop:pl-10">

					<h1 class="font-bold mobile:text-2xl text-3xl text-neon uppercase">{{pageData.main_block_header}}</h1>
					<p class="mobile:text-base text-xl text-justify mt-2 font-medium text-white" style="white-space: pre-wrap;">{{pageData.main_block_text}}</p>
					<div class="w-full text-center mt-8">
					<button class="mobile:mt-8 mt-8 mobile:h-12 h-16 px-4 rounded bg-rambam-darker-yellow-orange hover:bg-r-lighter-orange active:bg-r-darker-orange outline-none"><span class="text-white font-bold uppercase text-xl mobile:text-base" @click="route('home')">{{translations.try_mitvomoly_btn}}</span></button>
					</div>

				</div>
			</div>

		</div>

		<div class="w-full mobile:pt-10 tablet:pt-10 laptop:pt-20 wrapper flex justify-between items-center tablet:flex-col mobile:flex-col">
			<div class="flex-1">
				<h1 class="text-5xl font-bold uppercase mobile:pl-5 tablet:pl-5">Contact Us</h1>
			</div>
			<div class="flex-1 w-full mobile:px-5 tablet:px-5">

				<div class="w-full border p-3 mobile:mt-3 tablet:mt-3" 
				v-if="contactMsg"
				:class="{
					'bg-red-200 border-red-500' : contactMsg.header == 'error', 
					'bg-green-200 border-green-500' : contactMsg.header == 'success'}"> {{contactMsg.message}} 
				</div>

				<form class="w-full my-10">
				  
				  <div class="flex flex-wrap -mx-3 mb-4">
				    <div class="w-full px-3">
				      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
				        First Name
				      </label>
				      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" :class="{'border border-red-500': error && name == ''}" id="name" type="text" v-model="name">
				      <p class="text-red-500 text-xs italic" v-if="error && name == ''">* is a required field</p>
				    </div>
				  </div>				  
				  <div class="flex flex-wrap -mx-3 mb-4">
				    <div class="w-full px-3">
				      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
				        Last Name
				      </label>
				      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" :class="{'border border-red-500': error && surname == ''}" id="surname" type="text" v-model="surname">
				      <p class="text-red-500 text-xs italic" v-if="error && surname == ''">* is a required field</p>
				    </div>
				  </div>
				  <div class="flex flex-wrap -mx-3 mb-6">
				    <div class="w-full px-3">
				      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
				        E-mail
				      </label>
				      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" :class="{'border border-red-500': error && email == ''}" id="email" type="email" v-model="email">
				      <p class="text-red-500 text-xs italic" v-if="error && email == ''">* is a required field</p>
				    </div>
				  </div>				  
				  <div class="flex flex-wrap -mx-3 mb-6">
				    <div class="w-full px-3">
				      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
				        Message
				      </label>
				      <textarea class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-1 leading-tight h-40 focus:outline-none focus:bg-white focus:border-gray-500" :class="{'border border-red-500': message && message == ''}" id="message" type="message" v-model="message"></textarea> 
				      <p class="text-red-500 text-xs italic" v-if="message && message == ''">* is a required field</p>
				    </div>
				  </div>
			
				  <div class="md:flex md:items-center">
				    <div class="md:w-1/3">
				      <button class="shadow bg-r-purple hover:r-dark-purple focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="button" @click="submit">
				        Submit
				      </button>
				    </div>
				    <div class="md:w-2/3"></div>
				  </div>
				</form>
			</div>
		</div>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				name: '',
				surename: '',
				email: '',
				message: '',
				error: false,
				contactMsg: null
			}
		},

		computed: {
			translations: function () {
				return this.$store.getters.getTranslations;
			},

			pageData: function () {
				return this.$store.getters.getLandingPage
			}
		},

		methods: {

			submit: function () {
				if(
					this.name == "" || !this.name || 
					this.surname == "" || !this.surname || 
					this.email == "" || !this.email || 
					this.message == "" || !this.message
				){
					this.error = true
					return
				}
				this.$store.dispatch( 'contactForm' , 
				{
					name: this.name,
					surname: this.surname,
					email: this.email,
					message: this.message
				})
				.then(response => {
					console.log(response)
					this.contactMsg = response
				}).catch(response => {
					console.log(response)
					this.contactMsg = response
				})
			},

			route: function (route) {
				this.$router.push({name:route})
			},
		},

		beforeMount() {
			this.$store.dispatch('getLandingPage')
		},
	}
</script>