<template>
		<div class="">
		<div class="w-full flex flex-col px-5 ">


			<div class="px-5 pt-5 pb-3 md:p-10 md:pb-6">
				
				<div class="wrapper">
				
					<div class="overflow-hidden rounded-2xl md:rounded-4xl bg-green-200 relative">
							<h1 class="mobile:text-xl text-3xl text-r-dark-orange font-bold ml-10 mt-10">My Keys:</h1>
						<div class="flex h-full w-full p-10 px-12 items-center justify-between">
							<h1 class="mobile:text-lg text-2xl text-r-dark-orange font-bold">
								<img src="/assets/images/assets/key.png" class="h-12 mr-3  inline-block" alt="" style="transform: rotate(90deg);">
							{{keys.keys_data.earned}} earned</h1>							

							<h1 class="mobile:text-lg text-2xl text-r-dark-orange font-bold">
								<img src="/assets/images/assets/key-used.png" class="h-12 mr-3  inline-block" alt="" style="transform: rotate(90deg);">
							{{keys.keys_data.used}} used</h1>

						</div>
					
					</div>						

					<div class="overflow-hidden rounded-2xl md:rounded-4xl bg-white relative mt-10">
						<div class="h-full w-full p-10 px-12">
							<div class="grid-container">
								
								<div class="outline-none mobile:h-56 h-64 lg:h-17r my-2 md:my-3 cursor-pointer relative inline-block hover:m-0 focus:my-3 cursor-pointer relative" v-for="(key, index) in keys.data">
									<span class="mobile:text-lg text-2xl text-r-dark-orange font-bold w-full absolute text-center">
										{{key.amount}} keys <span>{{key.type}}</span> </span>
									<img v-if="key.type == 'earned'" :src="url+'key.png'" class="inline-block h-full p-12 image" alt="">
									<img v-if="key.type == 'used'" :src="url+'key-used.png'" class="inline-block h-full p-12 image" alt="">
								</div>

							</div>

							<div class="w-full flex justify-center"> 
				   				<vue-pagination :pagination="keys.meta"
		               			@paginate="getPage()"
		               			:offset="2">
			            		</vue-pagination>
							</div>
							
							
						</div>
					
					</div>								

				</div>
				

			
			</div>


		</div>		

	</div>

</template>
<script>
   
    import VuePagination from '../components/VuePagination.vue';

	export default {
		data() {
			return {
				url : '/assets/images/assets/'
			}
		},
		computed: {
			keys() {
				return this.$store.getters.getKeys
			}
		},

		components: {
			VuePagination
		},

		methods: {
			getPage: function () {
				this.$store.dispatch( 'getKeys', {PerPage:this.keys.meta.per_page, Page: this.keys.meta.current_page } );
				this.$router.push({name:'keys', params:{page:this.keys.meta.current_page}})
			}
		},
		
		beforeMount() {
      		this.$store.dispatch( 'getTranslations' );
      		this.$store.dispatch( 'getKeys', {PerPage:9, Page: parseInt(this.$route.params.page)} ).catch(response => {
      			
      			this.$store.dispatch( 'setPopUp', 
				{
					header:response.header, 
					data:{
						message:response.message,
					},
					model: 'defualt',
					type:'message',
					emoji:'danger',

				});
				this.$store.dispatch('setPopUpStatus', 1);
      		})
		},

	}
</script>