<style>
	.artifact-button{
		width: calc(100% - 3rem);
	}
 	@media screen and (max-width: 640px) {
 		.artifact-button{
			 width: calc(100% - 1rem);
		}
 	}
	
</style>
<template>
	<div class="">
		<div class="w-full flex flex-col px-5 ">


			<div class="px-5 pt-5 pb-3 md:p-10 md:pb-6">
				
				<div class="wrapper">
				
					<div class="text-left">
						<h1 class="text-r-dark-orange font-bold text-3xl uppercase mb-10">{{translations.quiz_question}}</h1>
					</div>
				
					<div class="relative overflow-hidden rounded-4xl relative tablet:pr-10 pr-12 mb-12 mobile:h-32 h-64 hover:scale-md  transition-smooth" v-for="answer in rambam.quiz.answers" :class="{'shrink-md' : answer.selected}" @click="$set(answer, 'selected', !answer.selected), submit()">
						<div class="absolute bg-rambam-yellow-orange h-full w-full">
							<div class="absolute bg-r-lighter-blue h-full w-full right-0 rounded-l-4xl mobile:px-4 px-10 mobile:py-2 py-8 flex justify-between items-center artifact-button">
								<h1 class="mobile:text-lg text-2xl text-r-dark-orange font-bold">{{answer.answer}}</h1>
								<div class="mobile:h-24 mobile:w-24 h-48 w-48 rounded-4xl bg-rambam-yellow-orange my-auto flex-shrink-0">
									<img :src="'/assets/images/assets/'+answer.image" class="w-full h-full" alt="">
								</div>
							</div>
						</div>
					</div>									

				</div>
				

				<div class="wrapper flex justify-between md:pt-8">
					<button class="z-10 mt-5 mobile:h-12 mobile:mr-2 h-20 px-4 rounded-1xl bg-rambam-darker-yellow-orange hover:bg-r-lighter-orange active:bg-r-darker-orange outline-none" @click="route('archive-rambam')">
						<span class="text-white font-bold uppercase mobile:text-base text-xl">{{translations.quiz_rewatch}}</span>
					</button>

					<!-- <button class="mt-5 mobile:h-12 h-20 px-4 rounded-1xl bg-rambam-darker-yellow-orange hover:bg-r-lighter-orange active:bg-r-darker-orange outline-none" @click="submit">
						<span class="text-white font-bold uppercase mobile:text-base text-xl">
							<span class="hidden md:block">{{translations.quiz_submit}}</span>
						</span>						
						<span class="text-white font-bold uppercase mobile:text-base text-xl">
							<span class="block md:hidden">Submit</span>
						</span>
					</button> -->
				</div>
				<!-- <p class="text-r-dark-blue mt-2 underline cursor" @click="">{{translations}}</p> -->
			</div>


		</div>		

	</div>
</template>
<script>


	export default {
		data() {
			return {
		
			}
		},
		computed: {

			appName: function () {
				return this.$store.getters.getApp.name;
			},	

			translations() {
				return this.$store.getters.getTranslations
			},

			userLoginStatus() {
				return this.$store.getters.getTokenLoadingStatus;
			},

			rambam() {
				return this.$store.getters.getRambam.data
			},

			fuel() {
				return {sum:5}
			},

			shiurId() {
				return parseInt(this.$route.params.shiur)
			}
		},

		methods: {
			
			// reset selected answers
			resetSelected: function ()
			{
				for(var i in this.rambam.quiz.answers){
					this.rambam.quiz.answers[i].selected = false
				}
				console.log(this.rambam.quiz.answers)
			},

			submit: function ()
			{
				var selected = this.rambam.quiz.answers.filter(function (val){
	  				return val.selected == true
				})
				if(selected.length == 0) {
					return
				}

				this.$store.dispatch( 'setPopUp', 
				{
					// header:'Hold Tight', 
					data:{
						// message:this.translations.msgs.quiz_check,
					},
					model: 'charging_loader',
					type:'message',
					image:'popup_gifs/charging_'+this.fuel.sum+'.gif',
					emoji:'wink',
					error:false,
					without_buttons: true,
				});
				this.$store.dispatch('setPopUpStatus', 1);
				
			
				
				setTimeout(() => {  

				this.$store.dispatch('submitArchiveRambamQuiz', {id:this.rambam.quiz.id, daily_id:this.rambam.id, shiur_id:this.shiurId, selected})
				.then(response => {
					// this.$store.dispatch('setPopUpStatus', 0);
					this.$store.dispatch( 'setPopUp', 
					{
						header:'', 
						data:{
							message:'',
						},
						model: 'defualt',
						type:'video',
						ended_route: 'home',
						videoOptions: {
							autoplay: true,
							controls: false,
							fill: true,
							aspectRatio: '16:9',
							sources: [
								{
									src:
										'/assets/videos/'+response.video,
									  type: "video/mp4"
								}
							]
						},
						error:false,
						without_buttons: true,
						lock:true,
					})

				}).catch(response => {
					
					if(response.header == 'incorrect_answer')
					{
						this.resetSelected()

						this.$store.dispatch( 'setPopUp', 
						{
							header:'', 
							data:{
								message:'',
							},
							model: 'defualt',
							type:'video',
							// ended_route: 'archive',
							videoOptions: {
								autoplay: true,
								controls: false,
								fill: true,
								aspectRatio: '16:9',
								sources: [
									{
										src:
											'/assets/videos/'+response.video,
										  type: "video/mp4"
									}
								]
							},
							error:false,
							without_buttons: true,
							lock:true,
						})
						this.$store.dispatch('setPopUpStatus', 1);
						return 
					}
					this.$store.dispatch( 'setPopUp', 
					{
						header:response.header, 
						data:{
							message:response.message,
						},
						model: 'defualt',
						type:'message',
						emoji:'danger',

					});
					this.$store.dispatch('setPopUpStatus', 1);

		        });	

				}, 3000);
				
				console.log(selected.length)
			},

			route: function (route) {
				this.$router.push({name:route})
			}

		},	

		beforeMount() {
      		this.$store.dispatch( 'getTranslations' );
      		this.$store.dispatch( 'getArchiveRambam', this.shiurId ).catch(response => {
				this.$router.back()
      			
      			this.$store.dispatch( 'setPopUp', 
				{
					header:response.header, 
					data:{
						message:response.message,
					},
					model: 'defualt',
					type:'message',
					emoji:'danger',

				});
				this.$store.dispatch('setPopUpStatus', 1);
      		})
		},

	}
</script>