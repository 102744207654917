import PointsAPI from '../api/points.js';

export const points = {

    state: {

    	points: {
    		leaderboard:[
                {
                    id: 1,
                    title:''
                },               
                {
                    id: 2,
                    title:''
                },                
                {
                    id: 3,
                    title:''
                },
                {
                    id: 4,
                    title:''
                },
            ],
            my_points:{},
    	},
    	points_loading: 0,

    },

    getters: {

    	getPoints(state) {
            return state.points;
        },    	

        getPointsLoadingStatus(state) {
            return state.points_loading;
        },        

    },
    
    actions: {           

        //get points
        getPoints( { commit, dispatch}, data){

            commit( 'setPointsLoadStatus', 1 );
            
            return new Promise((resolve, reject) => {

	            PointsAPI.getPoints(data)
	            .then( function( response ){
	                console.log(response.data)
	                commit( 'setPoints', response.data );
	                commit( 'setPointsLoadStatus', 2 );
	                resolve(response.data)
	            })
	            .catch( function(error){
	                commit( 'setPointsLoadStatus', 3 );
	                reject(error.response.data);
	            });

            });
        },           
      

    },

    mutations: {

	    setPoints( state, points ){
            state.points = points;
	    },

	    setPointsLoadStatus( state, status ){
      		state.points_loading = status;
	    },     


    },
}