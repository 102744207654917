/*
    Imports the API URL from the config.
*/
import { APP_CONFIG } from '../config.js';
import axios from 'axios'
import store from '../store'

export default {


 	//register user
 	registerUser: function(data){
        return axios.post(APP_CONFIG.API_URL+'/register', data); 
    }, 	    

    //user login api call
    userLogin: function(data){
        return axios.post(APP_CONFIG.API_URL+'/login', data); 
    },  

    //user logout api call
    userLogout: function(){
        return axios.post(APP_CONFIG.API_URL+'/logout'); 
    },    

    //user logout api call
    getUserData: function(){
        return axios.get(APP_CONFIG.API_URL+'/user'); 
    },

}