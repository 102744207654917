import CounterAPI from '../api/counter.js';

export const counter = {

    state: {

    	counter: {sum:0},
    	counter_loading: 0,

    },

    getters: {

    	getCounter(state) {
            return state.counter;
        },    	

        getCounterLoadingStatus(state) {
            return state.counter_loading;
        },        

    },
    
    actions: {           

        //get counter
        getCounter( { commit, dispatch}, data){

            commit( 'setCounterLoadStatus', 1 );
            
            return new Promise((resolve, reject) => {

	            CounterAPI.getCounter(data)
	            .then( function( response ){
	                commit( 'setCounter', response.data );
	                commit( 'setCounterLoadStatus', 2 );
	                resolve(response.data)
	            })
	            .catch( function(error){
	                commit( 'setCounterLoadStatus', 3 );
	                reject(error.response.data);
	            });

            });
        },           
      

    },

    mutations: {

	    setCounter( state, counter ){
            state.counter = counter;
	    },

	    setCounterLoadStatus( state, status ){
      		state.counter_loading = status;
	    },     


    },
}