import SettingsAPI from '../api/settings.js';

export const settings = {

    state: {
        settings_loading: 0,
        translations: {sidebar:{},actions:{},missions_types:{},pages:{password_reset:{}},user_info:{genders:{}}},
        grades: [],
    },

    getters: {

        getTranslations(state) {
            return state.translations
        },        

        getSettingsLoadingStatus(state) {
            return state.settings_loading
        },        

        getGrades(state) {
            return state.grades
        },        

    },

    actions: {   
        

        //get translations
        getTranslations({commit}) {
            
            commit( 'setSettingsLoadStatus', 1 );

            return new Promise((resolve, reject) => {

             SettingsAPI.getTranslations()
             .then( function( response ){
                console.log(response.data)
                 commit( 'setTranslations', response.data )
                 commit( 'setSettingsLoadStatus', 2 );
                 resolve(response.data)
             })
             .catch( function(){
                 commit( 'setSettingsLoadStatus', 3 );
                 reject(error);
             });
                

            })

        },        

        //get grades
        getGrades({commit}) {
            
            commit( 'setSettingsLoadStatus', 1 );

            return new Promise((resolve, reject) => {

             SettingsAPI.getGrades()
             .then( function( response ){
                console.log(response.data)
                 commit( 'setGrades', response.data )
                 commit( 'setSettingsLoadStatus', 2 );
                 resolve(response.data)
             })
             .catch( function(){
                 commit( 'setSettingsLoadStatus', 3 );
                 reject(error);
             });
                

            })

        },                     

    },

    mutations: {  

        setTranslations( state, translations ){
            state.translations = translations;
        },          

        setGrades( state, grades ){
            state.grades = grades;
        },        

        setSettingsLoadStatus( state, status ){
            state.settings_loading = status;
        },
    

    },
}