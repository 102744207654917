var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"pagination flex justify-center"},[(_vm.pagination.current_page > 1)?_c('li',{staticClass:"pagination-prev page-item",class:{
  'border-r-dark-orange' : _vm.color == 'orange',
  'border-scooter' : _vm.color == 'blue'
  }},[_c('a',{staticClass:"page-link text-xl",class:{'text-r-dark-orange' : _vm.color == 'orange', 'text-scooter' : _vm.color == 'blue'},attrs:{"href":"javascript:void(0)","aria-label":"Previous"},on:{"click":function($event){$event.preventDefault();return _vm.changePage(_vm.pagination.current_page - 1)}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("Prev")])])]):_vm._e(),_vm._v(" "),_vm._l((_vm.pagesNumber),function(page){return _c('li',{staticClass:"page-item",class:{
  'active': page == _vm.pagination.current_page, 
  'bg-scooter': page == _vm.pagination.current_page && _vm.color == 'blue', 
  'bg-r-dark-orange': page == _vm.pagination.current_page && _vm.color == 'orange', 
  'border-r-dark-orange hover:border-r-dark-orange hover:color-r-dark-orange' : _vm.color == 'orange',
  'border-scooter hover:border-scooter hover:color-scooter' : _vm.color == 'blue'
  }},[_c('a',{staticClass:"page-link text-xl",class:{'text-r-dark-orange' : _vm.color == 'orange', 'text-scooter' : _vm.color == 'blue'},attrs:{"href":"javascript:void(0)"},on:{"click":function($event){$event.preventDefault();return _vm.changePage(page)}}},[_vm._v(_vm._s(page))])])}),_vm._v(" "),(_vm.pagination.current_page < _vm.pagination.last_page)?_c('li',{staticClass:"pagination-next page-item",class:{
  'border-r-dark-orange' : _vm.color == 'orange',
  'border-scooter' : _vm.color == 'blue'
  }},[_c('a',{staticClass:"page-link text-xl",class:{'text-r-dark-orange' : _vm.color == 'orange', 'text-scooter' : _vm.color == 'blue'},attrs:{"href":"javascript:void(0)","aria-label":"Next"},on:{"click":function($event){$event.preventDefault();return _vm.changePage(_vm.pagination.current_page + 1)}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("Next")])])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }