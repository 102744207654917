<style lang="scss" scoped="true">
	.disabled{
		opacity: .5;
	}

	.grid-container {
	    display: grid;
	    grid-template-columns: repeat(auto-fit, minmax(9rem, -webkit-max-content)) !important;
	    grid-template-columns: repeat(auto-fit, minmax(9rem, max-content)) !important;
	    justify-content: center;
	    grid-gap: 1rem;
	    row-gap: 1rem;
	    justify-items: center;

	    @media only screen and (min-width: 640px) {
	        grid-template-columns: repeat(auto-fit, minmax(13rem, max-content)) !important;
	        row-gap: 3rem !important;
	    	grid-gap: 2rem;
	    }
	}
</style>
<template>
	<div class="">
		<div class="absolute w-full flex flex-col px-5 mt-12 mobile:bg-light-blue pb-32">

			<div class="mx-auto mobile:px-5 max-w-xs w-full sm:w-25r sm:h-12 bg-r-dark-orange rounded-full mobile:text-sm text-xl font-bold text-center text-white leading-10 sm:pt-1 capitalize text-ellipsis">
					
				welcome, <span v-if="loggedIn">{{user.name}} {{user.family}}</span><span v-else>{{translations.home_placeholder_username}}</span>

			</div>

			<div class="px-5 pt-5 pb-3 pt-10 md:p-10 md:pb-6">
				

				<div class="grid-container">
					<button class="mobile:h-48 mobile:w-32 h-56 outline-none cursor-pointer relative inline-block md:my-2 hover:m-0 focus:my-3 cursor-pointer" :class="{'disabled' : !loggedIn}" @click="route('points')">


						<div class="rounded-4xl flex-shrink-0">

							<div class="h-32 w-32 lg:h-48 lg:w-48 rounded-t-4xl overflow-hidden inline-block text-center">
								<img src="/assets/images/assets/home/leaderboard.png" class="h-full inline-block" alt="">
							</div>
							<!-- <div class="h-2/3 md:w-full rounded-b-4xl bottom-0 absolute" style="height: 30%;"> -->
								<h1 class="text-center text-r-dark-orange font-bold mobile:text-sm text-base md:text-xl uppercase mobile:mt-1 mt-5">{{translations.home_leaderboard}}</h1>
							<!-- </div> -->
						</div>

					</button>						
					<button class="mobile:h-48 mobile:w-32 h-56 outline-none cursor-pointer relative inline-block md:my-2 hover:m-0 focus:my-3 cursor-pointer" :class="{'disabled' : !loggedIn}" @click="route('daily-rambam')">


						<div class="rounded-4xl flex-shrink-0">

							<div class="h-32 w-32 lg:h-48 lg:w-48 rounded-t-4xl overflow-hidden inline-block text-center">
								<img src="/assets/images/assets/home/daily_mitzvomaly.png" class="h-full inline-block" alt="">
							</div>
							<!-- <div class="h-2/3 md:w-full rounded-b-4xl bottom-0 absolute" style="height: 30%;"> -->
								<h1 class="text-center text-r-dark-orange font-bold mobile:text-sm text-base md:text-xl uppercase mobile:mt-1 mt-5">{{translations.home_daily_rambam}}</h1>
							<!-- </div> -->
						</div>

					</button>			
					<button class="mobile:h-48 mobile:w-32 h-56 outline-none cursor-pointer relative inline-block md:my-2 hover:m-0 focus:my-3 cursor-pointer" @click="route('archive')">


						<div class="rounded-4xl flex-shrink-0">

							<div class="h-32 w-32 lg:h-48 lg:w-48 rounded-t-4xl overflow-hidden inline-block text-center">
								<img src="/assets/images/assets/home/archive.png" class="h-full inline-block" alt="">
							</div>
							<!-- <div class="h-2/3 md:w-full rounded-b-4xl  bottom-0 absolute" style="height: 30%;"> -->
								<h1 class="text-center text-r-dark-orange font-bold mobile:text-sm text-base md:text-xl uppercase mobile:mt-1 mt-5">{{translations.home_archive}}</h1>
							<!-- </div> -->
						</div>

					</button>					
					<button class="mobile:h-48 mobile:w-32 h-56 outline-none cursor-pointer relative inline-block md:my-2 hover:m-0 focus:my-3 cursor-pointer disabled" >

						<div class="rounded-4xl flex-shrink-0">

							<div class="h-32 w-32 lg:h-48 lg:w-48 rounded-t-4xl overflow-hidden inline-block text-center">
								<img src="/assets/images/assets/home/stats.png" class="h-full inline-block" alt="">
							</div>
							<!-- <div class="h-2/3 md:w-full rounded-b-4xl bottom-0 absolute" style="height: 30%;"> -->
								<h1 class="text-center text-r-dark-orange font-bold mobile:text-sm text-base md:text-xl uppercase mobile:mt-1 mt-5">{{translations.home_my_status}}</h1>
							<!-- </div> -->
						</div>

					</button>			
		
				</div>
			</div>
		</div>		

	</div>
</template>
<script>


	export default {
		data() {
			return {

			}
		},
		computed: {

			appName: function () {
				return this.$store.getters.getApp.name;
			},	

			translations() {
				return this.$store.getters.getTranslations
			},

			userLoginStatus() {
				return this.$store.getters.getTokenLoadingStatus;
			},

			user() {
				return this.$store.getters.getUser
			},

			loggedIn() {
				return this.$store.getters.getLoggedIn
			}

		},

		methods: {

			route: function (route) {
				this.$router.push({name:route})
			},

		},	

		beforeMount() {
      		this.$store.dispatch( 'getTranslations' );
      		if(this.$store.getters.getLoggedIn){
	      		this.$store.dispatch('getCounter');
				this.$store.dispatch('getFuel');
	      		this.$store.dispatch( 'getUser' );	
      		}
			
		},

	}
</script>