import RegisterAPI from '../api/register.js';

export const register = {

    state: {
    	register_loading: 0,
    },

    getters: {    	

        getRegisterLoadingStatus(state) {
            return state.register_loading;
        },      

    },
    actions: {   

        //pre register
        preRegister({commit, dispatch}, data) {
            
            commit( 'setRegisterLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                RegisterAPI.preRegister( data )
                .then( function( response ){
                    commit( 'setRegisterLoadStatus', 2 );
                    resolve(response.data)

                })
                .catch( function(error){
                    commit( 'setRegisterLoadStatus', 3 );
                    reject(error.response.data)
                }); 

            })
            
        },               
          
              

    },

    mutations: {

  	    setRegisterLoadStatus( state, status ){
    		state.register_loading = status;
  	    },    

    },
}