<style>
.slide {
  flex: 0 0 100%;
  height: 100%;
  margin: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}
.slide:first-of-type {
  opacity: 0;
}
.slide:last-of-type {
  opacity: 0;
}
</style>
<template>

	<div class="">
		
		<div class="w-full flex flex-col px-5 ">


			<div class="mobile:px-0 px-5 pb-3 md:px-10 md:pb-6">
				
				<div class="wrapper">
				
					<!-- <div class="w-full flex justify-between px-8">
							<h1 class="mobile:text-lg text-2xl text-r-dark-orange font-bold flex-shrink-0 mx-4 p-4 px-6 bg-r-lighter-blue rounded-2xl uppercase">Hall of fame</h1>
					</div>	 -->					
<!-- 
					<div class="w-full flex mobile:justify-center justify-end px-8 mt-4">
							<h1 class="mobile:text-lg text-2xl text-r-dark-orange font-bold flex-shrink-0 mx-4 p-4 px-6 bg-r-lighter-blue rounded-2xl uppercase">my rank:</h1>
					</div>	 -->					
					
					<div class="relative flex justify-between px-12 ">
						<button @click="previous" class="mobile:mt-2 mt-5 mobile:h-12 mobile:w-12 h-20 w-20 px-4 rounded-1xl bg-rambam-darker-yellow-orange hover:bg-r-lighter-orange active:bg-r-darker-orange outline-none">
							<img src="/assets/svg/previous.svg" class="image h-8 block mx-auto" alt="" >
						</button>
						<button @click="next" class="mobile:mt-2 mt-5 mobile:h-12 mobile:w-12 h-20 w-20 px-4 rounded-1xl bg-rambam-darker-yellow-orange hover:bg-r-lighter-orange active:bg-r-darker-orange outline-none">
							<img src="/assets/svg/next.svg" class="image h-8 block mx-auto" alt="" >
						</button>	
					</div>


					<div class="rounded-2xl md:rounded-4xl bg-r-lighter-blue relative mt-16">

						<div class="-mt-10 w-full mobile:h-16 md:h-20 absolute text-center">
							<div class="mx-auto relative rounded-2xl h-full inline-block">
								<div class="mobile:text-lg text-2xl text-r-dark-orange font-bold flex-shrink-0 mx-4 p-4 px-6 bg-rambam-yellow-orange rounded-2xl uppercase">{{currentLeaderboard.title}}</div>
							</div>
						</div>
						
						<div class='items-center flex-col flex h-full w-full pt-5 pb-10 mobile:px-3 md:px-12'>

							<!-- personal rank div -->
							<div class="w-full m-10 md:mx-24">
								<div class="h-full w-full right-0 rounded-l-4xl mobile:px-4 px-10 flex items-center" >

									<div class="mobile:h-16 mobile:w-16 h-24 w-24 rounded-2xl bg-rambam-yellow-orange my-auto flex-shrink-0 mobile:mr-2 md:mr-10 overflow-hidden ">
									
										<img :src="url+currentLeaderboard.data.user_rank.user.image" @error="imageLoadError" class="w-full h-full" alt="">
									</div>

									<div class="w-full flex flex-wrap justify-between items-center mobile:justify-center">

										<div class="mobile:text-center">
											<h1 class="mobile:text-base text-2xl text-r-dark-orange font-bold text-ellipsis md:w-18r mobile:mr-0 mr-5">name: {{currentLeaderboard.data.user_rank.user.name}} {{currentLeaderboard.data.user_rank.user.family}}</h1>									
											<h1 class="mobile:text-base text-2xl text-r-dark-orange font-bold text-ellipsis md:w-64 mobile:mr-0 mr-5">amount: {{currentLeaderboard.data.user_rank[currentLeaderboard.key]}}</h1>	
										</div>
										

										<div class="mobile:h-16 h-24 md:w-32 mobile:mt-1 justify-center px-4  bg-rambam-darker-yellow-orange block text-center" style="clip-path: polygon(0 64%, 0 0, 100% 0, 100% 64%, 50% 100%);">
											<span class="text-white font-bold uppercase mobile:text-base text-xl block pt-2">rank</span>
											<span class="text-white font-bold uppercase mobile:text-1xl text-3xl block -mt-2">#{{currentLeaderboard.data.user_rank.rank}}</span>
										</div>

									</div>
									
								</div>
							</div>

							<transition-group
						      class='flex justify-center items-center overflow-hidden w-full overflow-hidden rounded-2xl md:rounded-4xl bg-light-blue relative'
						      style="min-height: 25em;"
						      tag="div">
						      <div
						        v-for="slide in leaderboards" 
						        class='slide max-w-full'
						        v-bind:value="slide.id"
						        :key="slide.id">

						        <div class="bg-light-blue flex-1 mobile:p-0 p-10 max-w-full mobile:overflow-x-scroll">
						        	
						        	<div class="h-full w-full right-0 mb-5 rounded-l-4xl mobile:px-4 px-6 flex items-center" v-for="(points, index) in slide.data.data">

										<div class="mobile:h-16 mobile:w-16 h-24 w-24 rounded-2xl bg-rambam-yellow-orange my-auto flex-shrink-0 mobile:mr-4 mr-10 overflow-hidden ">
										
											<img :src="url+points.user.image" @error="imageLoadError" class="w-full h-full" alt="">
										</div>

										<div class="w-full flex flex-wrap justify-between mobile:justify-center items-center">

											<div class="mobile:text-center">
												<h1 class="mobile:text-sm text-2xl text-r-dark-orange font-bold text-ellipsis md:w-18r mobile:mr-0 mr-5">name: <span class="text-ellipsis mobile:w-32 mobile:block">{{points.user.name}} {{points.user.family}}</span></h1>
												<h1 class="mobile:text-sm text-2xl text-r-dark-orange font-bold text-ellipsis md:w-64 mobile:mr-0 mr-5">amount: <span class="mobile:block">{{points[slide.key]}}</span></h1>	
											</div>
											

											<div class="mobile:h-16 h-24 md:w-32 mobile:mt-1 justify-center px-4  bg-rambam-darker-yellow-orange block text-center" style="clip-path: polygon(0 64%, 0 0, 100% 0, 100% 64%, 50% 100%);">
												<span class="text-white font-bold uppercase mobile:text-sm text-xl block pt-2">rank</span>
												<span class="text-white font-bold uppercase mobile:text-base mobile:mt-0 text-3xl block -mt-2">#{{index+1}}</span>
											</div>

										</div>
										
									</div>

						        </div>
						        <!-- <h4> {{ slide.title }} </h4> -->
						      </div>
						    </transition-group>	
						</div>

					</div>
				
				</div>

			</div>

		</div>		

	</div>



  
</template>
<script>
export default {
  data () {
    return {
	  leaderboards: [{
        id: 1,
        title: "",
       	data: {
       		data: [{
                user: {},
            }],
            user_rank: {},
        }
      }],
      default: '/assets/images/avatars/default.png',
      url: '/assets/images/avatars/',
    }
  },
  computed: {
  	currentLeaderboard() {
  		return this.leaderboards[0+2]
  	}
  },
  methods: {
    next () {
      const first = this.leaderboards.shift()
      this.leaderboards = this.leaderboards.concat(first)
    },
    previous () {
      const last = this.leaderboards.pop()
      this.leaderboards = [last].concat(this.leaderboards)
    },
    //handle broken user profile image
	imageLoadError: function () {
		event.target.src = this.default;
	},
  },

  beforeMount() {
  	this.$store.dispatch('getPoints').then(response => {
  		console.log(response)
		this.leaderboards = response   
    })
  }
}
</script>