<style lang="scss" scoped="true">
	.fab-container{
	    position: fixed;
	    left: 26px;
	    bottom: 33px;
	    z-index: 120;
	    transition: color .2s ease-in-out;
	    -webkit-animation-duration: 1.5s;
	    animation-duration: 1.5s;
   }
    .fab-button{

      color: #fff;
      text-align: center;
      line-height: 60px;
      font-size: 58px;
      width: 13rem;
	  height: 140px;
      border-radius: 100%;
      cursor: pointer;
     
		@media only screen and (max-width: 768px) {
            width: 6.5rem;
      		height: 6.5rem;
        }

        img{
        	position: absolute;
        	bottom: 0;
        }    

        
    }
</style>
<template>
	<div class="fab-container">
		<div class="fab-button" v-tooltip="translations.counter_tooltip" v-if="loggedIn">
			<img :src="url+'613_counter.png'" class="" alt="">
			<div class="absolute bottom-0 text-center px-4 mx-auto w-full pb-8 mobile:pb-0"><h1 class="font-Alef font-bold text-green-500 mobile:text-2xl text-3xl">{{counter.sum}}</h1></div>
		</div>
	</div>
</template>
<script>
	// import VTooltip from 'v-tooltip'

	export default {
		data() {
			return {
				url : '/assets/images/assets/613counter/',
				oldValue: 3,
				newValue: 4,
			}
		},

		computed: {

			counter() {
				return this.$store.getters.getCounter
			},

			translations() {
				return this.$store.getters.getTranslations
			},
			
			loggedIn() {
				return this.$store.getters.getLoggedIn
			}
		},

		methods: {
			flicker: function () {
				console.log(this.oldValue)
    			
    			var _this = this;

				setInterval(function() {
					switch(_this.fuel){
			            case _this.oldValue:
			              _this.fuel = _this.newValue
			            break;            
			            case _this.newValue:
			             _this.fuel = _this.oldValue
			            break;	            
			        }
				}, 700);
			}
		},

		components:{
			// VTooltip
		},

		mounted() {
			this.$store.dispatch('getCounter')
		},

		
	}
</script>