import RambamAPI from '../api/rambam.js';

export const rambam = {

    state: {

    	rambam: {data:{questions:{},quiz:{},shiur:''}},
    	rambam_loading: 0,

    },

    getters: {

    	getRambam(state) {
            return state.rambam;
        },    	

        getRambamLoadingStatus(state) {
            return state.rambam_loading;
        },        

    },
    
    actions: {           

        //get rambam
        getDailyRambam( { commit, dispatch}, data){

            commit( 'setRambamLoadStatus', 1 );
            
            return new Promise((resolve, reject) => {

	            RambamAPI.getRambam(data)
	            .then( function( response ){
	                console.log(response.data)
	                commit( 'setRambam', response.data );
	                commit( 'setRambamLoadStatus', 2 );
	                resolve(response.data)
	            })
	            .catch( function(error){
	                commit( 'setRambamLoadStatus', 3 );
	                reject(error.response.data);
	            });

            });
        },         

        //get rambam demo guest mode
        getDailyRambamDemo( { commit, dispatch}, data){

            commit( 'setRambamLoadStatus', 1 );
            
            return new Promise((resolve, reject) => {

                RambamAPI.getRambamDemo(data)
                .then( function( response ){
                    console.log(response.data)
                    commit( 'setRambam', response.data );
                    commit( 'setRambamLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setRambamLoadStatus', 3 );
                    reject(error.response.data);
                });

            });
        },          

        //get archived rambam 
        getArchiveRambam( { commit, dispatch}, data){

            commit( 'setRambamLoadStatus', 1 );
            
            return new Promise((resolve, reject) => {

                RambamAPI.getArchiveRambam(data)
                .then( function( response ){
                    console.log(response.data)
                    commit( 'setRambam', response.data );
                    commit( 'setRambamLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setRambamLoadStatus', 3 );
                    reject(error.response.data);
                });

            });
        },           

        //submit completed rambam
        submitDailyRambamQuiz( { commit }, data){

            commit( 'setRambamLoadStatus', 1 );
            
            return new Promise((resolve, reject) => {

                RambamAPI.submitDailyRambamQuiz(data)
                .then( function( response ){
                    console.log(response)
                    // commit( 'setRambam', response.data );
                    commit( 'setRambamLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setRambamLoadStatus', 3 );
                    reject(error.response.data);
                });
            
            })
        },        

        //submit completed rambam guest mode/demo
        submitDailyRambamQuizDemo( { commit }, data){

            commit( 'setRambamLoadStatus', 1 );
            
            return new Promise((resolve, reject) => {

                RambamAPI.submitDailyRambamQuizDemo(data)
                .then( function( response ){
                    console.log(response)
                    // commit( 'setRambam', response.data );
                    commit( 'setRambamLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setRambamLoadStatus', 3 );
                    reject(error.response.data);
                });
            
            })
        },        

        //submit completed archived rambam guest mode/demo
        submitArchiveRambamQuiz( { commit }, data){

            commit( 'setRambamLoadStatus', 1 );
            
            return new Promise((resolve, reject) => {

                RambamAPI.submitArchiveRambamQuiz(data)
                .then( function( response ){
                    console.log(response)
                    // commit( 'setRambam', response.data );
                    commit( 'setRambamLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setRambamLoadStatus', 3 );
                    reject(error.response.data);
                });
            
            })
        },

        //reset rambam
        resetDailyRambam({commit})
        {
            commit( 'setRambamLoadStatus', 1 );
            commit('resetDailyRambam');
            commit( 'setRambamLoadStatus', 2 );
        }       

    },

    mutations: {

	    setRambam( state, rambam ){
            state.rambam = rambam;
	    },

	    setRambamLoadStatus( state, status ){
      		state.rambam_loading = status;
	    },

        resetDailyRambam(state, rambam){
            state.rambam = {data:{questions:{},quiz:{},shiur:''}}
        },


    },
}